import { NgModule } from '@angular/core';
import { NgxLowerCaseDirective } from './ngx-lower-case.directive';

@NgModule({
  imports: [],
  declarations: [NgxLowerCaseDirective],
  exports: [NgxLowerCaseDirective],
})
export class NgxLowerCaseDirectiveModule {
}
